<template>
  <v-container v-if="loadedInfo">
    <v-card elevation="5" rounded="xl">
      <v-img
        :src="micrositeInfo.banner"
        width="auto"
        height="400px"
        v-if="micrositeInfo.banner"
      >
      </v-img>
      <!-- #1b273f button color -->
      <v-toolbar
        :height="$vuetify.breakpoint.mdAndDown ? 'auto' : '150px'"
        :color="micrositeInfo.micrositeConfiguration.primaryColor"
        class="d-flex flex-column"
        :class="$vuetify.breakpoint.mdAndDown ? '' : 'pl-5'"
      >
        <v-card
          width="100%"
          color="transparent"
          elevation="0"
          class="d-flex align-center"
        >
          <v-row justify="center">
            <span
              :style="
                $vuetify.breakpoint.mdAndDown
                  ? 'font-size: 25px; text-align:center'
                  : 'font-size: 33px;'
              "
              style="color: white; font-weight: 800; word-break: break-word"
            >
              Eventos {{ micrositeInfo.organization.company}}
            </span>
          </v-row>
        </v-card>
        <template v-slot:extension>
          <v-tabs
            v-model="tab"
            :fixed-tabs="$vuetify.breakpoint.mdAndDown"
            center
            active-class="font-weight-bold"
          >
            <v-tabs-slider color="transparent"></v-tabs-slider>
            <v-divider inset vertical style="background: white"></v-divider>
            <v-tab class="white--text text-capitalize subtitle">
              {{ items[2].title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <!-- Third Page -->
        <v-tab-item>
          <v-card color="#f9fafc" width="100%" height="100%">
            <Events
              :color="micrositeInfo.micrositeConfiguration.primaryColor"
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <!-- Footer -->
    <v-row class="my-10 grayscale-disabled-element">
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-img
          max-width="150"
          height="auto"
          src="@/assets/images/weChamber/poweredby.svg"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Events from "./Events.vue"
import { mapActions, mapMutations } from "vuex";

export default {
  components: {
    Events,
  },
  data() {
    return {
      tab: null,
      items: [
        { title: "Inicio", rBar: true },
        { title: "Directorio", rBar: true },
        { title: "Cursos", rBar: false },
      ],
      micrositeInfo: {},
      loadedInfo: false,
      micrositeEvents: [],
      loadingEvents: false,
      noMoreEvents: false,
      eventsPage: 1,
      eventSearch: "",
    };
  },
  computed: {
    micrositeId() {
      return this.$route.params.id;
    },
    filteredEvents() {
      if (this.eventSearch == "") {
        return this.micrositeEvents;
      } else {
        return this.micrositeEvents.filter((event) => {
          return (
            event.title
              .toLowerCase()
              .includes(this.eventSearch.toLowerCase()) ||
            event.description
              .toLowerCase()
              .includes(this.eventSearch.toLowerCase())
          );
        });
      }
    },
  },
  methods: {
    ...mapActions("microsite", ["getMicrositeInfo"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    async getMicrosite() {
      try {
        this.loading();
        const microsite = await this.getMicrositeInfo(this.$route.params.id);
        if (microsite.data.migrationTag) {
          this.items[1].title += " " + microsite.data.migrationTag;
        } else {
          this.items[1].title += " AMPI";
        }
        if (microsite.status) {
          this.micrositeInfo = microsite.data;
        } else {
          console.log(microsite.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
        this.loadedInfo = true;
      }
    },

    /*handleScroll() {
      window.onscroll = async () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight + 50 >=
          document.documentElement.offsetHeight;
        console.log(bottomOfWindow);
        if (bottomOfWindow && !this.loadingMembers) {
          if (this.tab == 1) {
            if (this.memberSearch == "" && !this.noMoreMembers) {
              await this.getMembers();
            } else {
              if (!this.noMoreResults && this.membersSearchPage > 1) {
                await this.getMembersSearch(false);
              }
            }
          } else if (this.tab == 2 && !this.noMoreEvents) {
            await this.getEvents();
          }
        }
      };
    },*/
  },
  created() {
    this.getMicrosite();
    if (this.$route.query.step == "members") {
      this.tab = 1;
    } else if (this.$route.query.step == "events") {
      this.tab = 2;
    }
  },
};
</script>

<style scoped>
input::placeholder {
  font-size: 15px;
  color: #d4dcd4;
}

input {
  padding-left: 20px;
  width: 30rem;
  max-width: 30rem;
  min-width: 10rem;
  border: 0;
  border-color: transparent;
  background-color: #f9fafc;
}

input:focus {
  outline: none;
  border: 1px solid transparent;
}

p {
  font-size: 10px;
  text-transform: uppercase;
  color: #222b4c;
}

span {
  color: #222b4c;
  font-weight: 900;
}
</style>
