<template>
  <v-container>
    <!-- Search Bar -->
    <v-row class="mx-5">
      <v-col cols="12">
        <div class="d-flex flex-row my-4">
          <v-btn
            fab
            small
            elevation="0"
            class="rounded-pill rounded-r-0"
            :color="color"
          >
            <v-icon size="20">mdi-magnify</v-icon></v-btn
          >
          <input
            type="text"
            placeholder="Buscar en cursos"
            class="rounded-pill rounded-l-0"
            v-model="search"
            @keyup="getEvents"
          />
        </div>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="10" md="6">
        <v-pagination
          :length="totalPages"
          v-model="eventsPage"
          :total-visible="7"
          @input="getEvents"
          class="mb-4"
        ></v-pagination>
      </v-col>
      <v-col lg="3" md="4" sm="6" cols="10" class="absolute-right">
        <v-row align="center" justify="center" justify-md="end" class="mx-0">
          <span>Mostrar</span>
          <v-col cols="6" sm="4" class="ma-0">
            <v-select
              :items="items"
              v-model="itemsToGet"
              @change="getEvents"
            ></v-select>
          </v-col>
          <span v-if="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md"
            >por página</span
          >
        </v-row>
      </v-col>
    </v-row>

    <!-- Courses Card List -->
    <v-row class="mx-5 pb-5" align="center">
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="event in micrositeEvents"
        :key="event._id"
        class="pr-0"
      >
        <v-card
          height="250px"
          width="100%"
          style="border-radius: 15px"
          elevation="0"
          @click="goToEvent(event._id)"
        >
          <v-img
            class="rounded-b-0"
            :src="
              event.banner != '' && event.banner != null
                ? event.banner
                : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image@2x.png'
            "
            height="70%"
          />
          <v-list-item two-line>
            <v-list-item-content>
              <span
                class="d-inline-block text-truncate event-name"
              >
                {{ event.name }}
              </span>
              <div class="d-flex justify-space-between event-date">
                <p>
                  {{ event.dateConfiguration.startDate | formatDate }} 
                  <span v-if="event.dateConfiguration.startDate!==event.dateConfiguration.endDate">
                   / {{ event.dateConfiguration.endDate | formatDate }}
                  </span>
                </p>
                <p style="color: green; font-weight: bold">Vigente</p>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <!-- Courses Card list -->
    <v-row
      class="px-5 pb-5 mb-2"
      justify="center"
      v-if="micrositeEvents.length == 0 && noResults"
    >
      <span>No se han encontrado resultados</span>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  name: "EventsTab",
  props: {
    color: {
      type: String,
      default: "#000",
    },
  },
  data() {
    return {
      search: "",
      totalPages: 0,
      eventsPage: 1,
      micrositeEvents: [],
      items: [10, 20, 30, 40, 50],
      itemsToGet: 20,
      noResults: false,
    };
  },
  methods: {
    ...mapActions("microsite", ["getEventsPageSearch"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    goToEvent(id) {
      this.$router.push(`/micrositio/${id}`);
    },

    
    async getEvents() {
      try {
        this.noResults = false;
        const events = await this.getEventsPageSearch({
          id: this.$route.params.id,
          page: this.eventsPage,
          perPage: this.itemsToGet,
          name: this.search,
        });
        if (events.status) {
          this.micrositeEvents = events.data.events;
          if (this.micrositeEvents.length === 0) {
            this.noResults = true;
          }
          this.totalPages = events.data.totalPages;
        } else {
          console.log(events.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getEvents();
  },
};
</script>

<style scoped>
  .event-name{
    font-size: 17px;
    font-weight: 800;
  }

  .event-date{
    font-size: 14px;
  }
</style>